/*
* Laraberg
*/

.block-editor-block-list__layout {
  .wp-block {
    margin-left: 0;
    margin-right: 0;
    max-width: 100%;
  }
}

/*
* 文字揃え
*/
.has-text-align-center {
  text-align: center;
}

/*
* 画像
*/
.alignfull {
  img {
    width: 100%;
  }
}

/*
* 文字枠
*/
.boxBorder {
  display: inline-block;
  padding: 8px 16px;
  border: 3px solid #a2a2a2;
}