/*
* table用
*/

th, td {
  vertical-align: middle;
}

/*
* sort用
*/
.tableSort {
  .handle {
    color: #a2a2a2;
    cursor: grab;
  }
}
