/*
* Form
*/

form {
  .form-group {
    margin-bottom: 1.6rem;
  }

  /*
  * ラジオボタン・チェックボックス
  */
  input[type=radio]
  , input[type=checkbox] {
    margin-right: 0.8rem;
  }

  fieldset {
    margin-bottom: 3.2rem;
  }

  /*
  * legend
  */
  legend {
    margin-bottom: 1.6rem;
    font-size: $font-size-large-pc;
  }

}
