/*
* bootstrap置き換え
*/
:root {
//  font-size: 62.5%;
  $font-size-base: 16px;
}

/*
* 入力エリア
*/
input[type=text]
, input[type=name]
, input[type=password]
, input[type=email]
, input[type=date]
, input[type=number]
, input[type=file]
, textarea
, select
{
  font-size: $font-size-sp !important;
  @include pc() {
    font-size: $font-size-pc !important;
  }
}
.form-select {
  font-size: $font-size-sp;
  @include pc() {
    font-size: $font-size-pc;
  }
}

/*
* ラベル
*/
.form-label {
  margin-bottom: 0;
}

/*
* ボタン
*/
.btn {
  padding: 0.4rem 2.4rem;
  font-size: $font-size-sp;
  @include pc() {
    font-size: $font-size-pc;
  }
  &.btn-sm {
    font-size: $font-size-small-sp;
    @include pc() {
      font-size: $font-size-small-pc;
    }
  }
  &.btn-lg {
    font-size: $font-size-large-sp;
    @include pc() {
      font-size: $font-size-large-pc;
    }
  }
}

/*
* カード
*/
.card {
  .card-header {
    padding: 1.6rem;
    font-size: 1.6rem;
    font-weight: bold;
  }
  .card-body {
    padding: 1.6rem;
  }
  .card-footer {
    padding: 1.6rem;
  }
}