/*
* ベースCSS
*/
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@100;400;700&display=swap');

html {
  font-size: 62.5%;
  scroll-behavior: smooth;
}
body {
  width: 100%;
  font-family: 'Noto Sans JP', sans-serif;
  font-weight: 400;
  font-size: 1.2em;
  color: $color-text;
	font-feature-settings: "palt" 1;
  @include pc(){
    font-size: 1.4em;
  }
}

a {
  opacity: 1.0;
  transition: all 0.6s;
  &:hover {
    text-decoration: none;
    opacity: 0.8;
  }
}

/*
* PC, スマホ表示・非表示
*/
.spOnly {
  display: block;
  @include pc() {
    display: none;
  }
}
.pcOnly {
  display: none;
  @include pc() {
    display: block;
  }
}

/*
* PCコンテンツの最大幅
*/
.pcContents {
  margin: 0 auto;
  padding: 0 16px;
  width: 100%;
  @include pc() {
    padding: 0;
    max-width: 980px;
  }
}

/*
* ページャー
*/
.navPager {
  margin-top: 2.4rem;
  font-size: 1.4rem;
  background-color: transparent;
  @include pc() {
    font-size: 1.6rem;
  }
  ul {
    margin: 0 auto;
  }
}

/*
* プレビュー画像wrapper
*/
.previewImage {
  margin-top: 0.8rem;
  margin-bottom: 0.8rem;
}
/*
* 画像最大プレビューサイズ
*/
.previewImageMaxSize {
  max-width: 240px;
  max-height: 240px;
}

