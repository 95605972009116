/*
* mixin・変数
*/

/*
* メディアクエリ
*/
$breakpoints: (
  pc: "768px",
  sp: "767px",
);
@mixin pc($breakpoint: pc) {
  @media (min-width: #{map-get($breakpoints, $breakpoint)}) {
    @content;
  }
}
@mixin mq($breakpoint: pc) {
  @media (min-width: #{map-get($breakpoints, $breakpoint)}) {
    @content;
  }
}

/*
* 文字サイズ
*/
$font-size-sp: 1.2rem;
$font-size-pc: 1.4rem;
$font-size-small-sp: 1.1rem;
$font-size-small-pc: 1.2rem;
$font-size-large-sp: 1.6rem;
$font-size-large-pc: 1.8rem;

/*
* カラー
*/
$color-black: #000000;
$color-white: #FFFFFF;
$color-background: #f3f3f3;
$color-red: #ff002a;
$color-blue: #040f51;
$color-green: #006666;
$color-yellow: #FFF700;
$color-light-blue: #828aa8;
$color-light-gray: #aaaaaa;
$color-dark-gray: #616E80;
/*
* 文字カラー
*/
$color-text: #595757;

$color-admin-header-back: #272c33;

$color-link: #2196F3;
